export const HistoryIcon = ({
  width = 20,
  height = 20,
  stroke = '#323232',
  strokeWidth = 1.5,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    className={'history-icon ' + className}
  >
    <path
      d='M2.9329 7.49901C4.04964 4.3533 7.11178 2.32497 10.4439 2.52379C13.776 2.7226 16.5752 5.10066 17.31 8.35684C18.0448 11.613 16.538 14.9627 13.6142 16.5732C10.6903 18.1837 7.05387 17.6669 4.69447 15.3056M7.49897 12.5011L10 10V5.83165M5.83161 7.49901H2.49689V4.16428'
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
