export const RATE_NOTE_LABEL = 'Rate Note';
export const RATE_TYPE_LABEL = 'Rate Type';
export const RTW_LIST_COLUMN_LABEL = 'RTW';
export const DBS_LIST_COLUMN_LABEL = 'DBS';

/*
  these columns determine backend existence
  each of these columns will create column_ids and thus list_column_value entries
  in the DB (these do not affect display)
  (object keys follow DB list_column fields)
*/
export const DEFAULT_LIST_COLUMNS_TO_CREATE_US: CreateCastingListColumn[] = [
  /*
    do not add voucher_status
    to this list because their columns are populated
    by the list_membership columns
   (list_membership has its own endpoint)
  */
  {
    label: 'Status',
    type: 'list_status',
    validation_rules: {
      datasource: {
        key: 'list_status',
        source: 'list_membership',
      },
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'First Name',
    type: 'userModalTrigger',
    validation_rules: { datasource: { key: 'first_name', source: 'user' } },
    is_default: true,
  },
  {
    label: 'Last Name',
    type: 'userModalTrigger',
    validation_rules: { datasource: { key: 'last_name', source: 'user' } },
    is_default: true,
  },
  {
    label: 'Email',
    type: 'string',
    validation_rules: {
      datasource: { key: 'email', source: 'user' },
    },
    is_default: true,
  },
  {
    label: 'Phone',
    type: 'string',
    validation_rules: {
      combo_datasource: [
        { key: 'phone_number', source: 'user' },
        { key: 'opt_in', source: 'user_notification_preference' },
      ],
    },
    is_default: true,
  },
  {
    label: 'Union',
    type: 'union',
    validation_rules: {
      datasource: { key: 'union_status', source: 'user' },
    },
    is_default: true,
  },
  {
    label: 'Gender',
    type: 'gender',
    validation_rules: { datasource: { key: 'gender', source: 'actor_info' } },
    is_default: true,
  },
  {
    label: 'Role',
    type: 'text',
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'Type',
    type: 'role_type',
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'role_description',
    type: 'text',
    validation_rules: { editable: true },
    is_default: true,
  },
  {
    label: 'Service',
    type: 'service',
    validation_rules: {
      datasource: { key: 'call_in_service', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Rate',
    type: 'rate',
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'Ethnicity',
    type: 'ethnicity',
    validation_rules: {
      combo_datasource: [
        { key: 'primary_ethnicity', source: 'actor_info' },
        { key: 'secondary_ethnicity', source: 'actor_info' },
        { key: 'tertiary_ethnicity', source: 'actor_info' },
      ],
    },
    is_default: true,
  },
  {
    label: 'Age Range',
    type: 'age_range',
    validation_rules: {
      combo_datasource: [
        { key: 'min_portrayable_age', source: 'actor_info' },
        { key: 'max_portrayable_age', source: 'actor_info' },
      ],
    },
    is_default: true,
  },
  {
    label: 'Minors Age',
    type: 'string',
    validation_rules: {
      datasource: { key: 'birthday', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Height',
    type: 'string',
    validation_rules: {
      datasource: { key: 'height', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Weight',
    type: 'string',
    validation_rules: {
      datasource: { key: 'weight', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'I-9',
    type: 'i9',
    validation_rules: {
      combo_datasource: [
        {
          key: 'status',
          source: 'i9_docs',
        },
        {
          key: 'expiration_date',
          source: 'i9_docs',
        },
        {
          key: 'latest_action_date',
          source: 'i9_docs',
        },
        {
          key: 'project_name',
          source: 'i9_docs',
        },
        {
          key: 'rejection_notes',
          source: 'i9_docs',
        },
      ],
    },
    is_default: true,
  },
  {
    label: 'Chest',
    type: 'string',
    validation_rules: {
      datasource: { key: 'chest_bust', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Dress',
    type: 'string',
    validation_rules: {
      datasource: { key: 'dress', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'waist', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Hip',
    type: 'string',
    validation_rules: {
      datasource: { key: 'hip', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Hat',
    type: 'string',
    validation_rules: {
      datasource: { key: 'hat', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Neck',
    type: 'string',
    validation_rules: {
      datasource: { key: 'neck', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Shirt',
    type: 'string',
    validation_rules: {
      datasource: { key: 'shirt_size', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Sleeve',
    type: 'string',
    validation_rules: {
      datasource: { key: 'sleeve', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Pants',
    type: 'string',
    validation_rules: {
      datasource: { key: 'pants', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Inseam',
    type: 'string',
    validation_rules: {
      datasource: { key: 'inseam', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Shoe',
    type: 'string',
    validation_rules: {
      datasource: { key: 'shoe_size', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Bra',
    type: 'bra',
    validation_rules: {
      combo_datasource: [
        { key: 'bust', source: 'actor_info' },
        { key: 'cup', source: 'actor_info' },
      ],
    },
    is_default: true,
  },
  {
    label: 'Notes',
    type: 'text', // type 'text' will map to TextAreaRender (not StringRender)
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'Selects',
    type: 'gallery_response_selects',
    validation_rules: {
      combo_datasource: [
        {
          key: 'list_gallery_name',
          source: 'list_gallery',
        },
        {
          key: 'response',
          source: 'list_gallery_response',
        },
      ],
    },
    is_default: true,
  },
];

export const DEFAULT_LIST_COLUMNS_TO_CREATE_UK: CreateCastingListColumn[] = [
  {
    label: 'Status',
    type: 'list_status',
    validation_rules: {
      datasource: {
        key: 'list_status',
        source: 'list_membership',
      },
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'First Name',
    type: 'userModalTrigger',
    validation_rules: { datasource: { key: 'first_name', source: 'user' } },
    is_default: true,
  },
  {
    label: 'Last Name',
    type: 'userModalTrigger',
    validation_rules: { datasource: { key: 'last_name', source: 'user' } },
    is_default: true,
  },
  {
    label: 'Email',
    type: 'string',
    validation_rules: {
      datasource: { key: 'email', source: 'user' },
    },
    is_default: true,
  },
  {
    label: 'Phone',
    type: 'string',
    validation_rules: {
      combo_datasource: [
        { key: 'phone_number', source: 'user' },
        { key: 'opt_in', source: 'user_notification_preference' },
      ],
    },
    is_default: true,
  },
  {
    label: 'Gender',
    type: 'gender',
    validation_rules: { datasource: { key: 'gender', source: 'actor_info' } },
    is_default: true,
  },
  {
    label: 'Role',
    type: 'text',
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'Type',
    type: 'role_type',
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'role_description',
    type: 'text',
    validation_rules: { editable: true },
    is_default: true,
  },
  {
    label: 'Ethnicity',
    type: 'ethnicity',
    validation_rules: {
      combo_datasource: [
        { key: 'primary_ethnicity', source: 'actor_info' },
        { key: 'secondary_ethnicity', source: 'actor_info' },
        { key: 'tertiary_ethnicity', source: 'actor_info' },
      ],
    },
    is_default: true,
  },
  {
    label: 'Age',
    type: 'string',
    validation_rules: {
      datasource: { key: 'birthday', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Height',
    type: 'string',
    validation_rules: {
      datasource: { key: 'height', source: 'actor_info' },
    },
    is_default: true,
  },
  // measurements
  {
    label: 'Dress (UK)',
    type: 'string',
    validation_rules: {
      datasource: { key: 'uk_dress', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Collar',
    type: 'string',
    validation_rules: {
      datasource: { key: 'neck', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Chest/Bust',
    type: 'string',
    validation_rules: {
      datasource: { key: 'chest_bust', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Chest',
    type: 'string',
    validation_rules: {
      datasource: { key: 'chest', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Bra',
    type: 'bra_uk',
    validation_rules: {
      combo_datasource: [
        { key: 'underbust', source: 'actor_info' },
        { key: 'cup', source: 'actor_info' },
      ],
    },
    is_default: true,
  },
  {
    label: 'Waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'waist', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Natural Waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'natural_waist', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Trouser Waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'trouser_waist', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Hips',
    type: 'string',
    validation_rules: {
      datasource: { key: 'hip', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Inside Leg',
    type: 'string',
    validation_rules: {
      datasource: { key: 'inseam', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Shoe (UK)',
    type: 'string',
    validation_rules: {
      datasource: { key: 'uk_shoe', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Sleeve',
    type: 'string',
    validation_rules: {
      datasource: { key: 'sleeve', source: 'actor_info' },
    },
    is_default: true,
  },
  {
    label: 'Head',
    type: 'string',
    validation_rules: {
      datasource: { key: 'head', source: 'actor_info' },
    },
    is_default: true,
  },
  // end of measurements
  {
    label: 'Notes',
    type: 'text', // type 'text' will map to TextAreaRender (not StringRender)
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: RATE_NOTE_LABEL,
    type: 'text', // type 'text' will map to TextAreaRender (not StringRender)
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: RATE_TYPE_LABEL,
    type: 'rate_type',
    validation_rules: {
      editable: true,
    },
    is_default: true,
  },
  {
    label: 'Selects',
    type: 'gallery_response_selects',
    validation_rules: {
      combo_datasource: [
        {
          key: 'list_gallery_name',
          source: 'list_gallery',
        },
        {
          key: 'response',
          source: 'list_gallery_response',
        },
      ],
    },
    is_default: true,
  },
  {
    label: RTW_LIST_COLUMN_LABEL,
    type: 'right_to_work_uk',
    validation_rules: {
      combo_datasource: [
        {
          key: 'id',
          source: 'employment_verification_uk',
        },
        {
          key: 'validation',
          source: 'employment_verification_validation_uk',
        },
      ],
    },
    is_default: true,
  },
  {
    label: DBS_LIST_COLUMN_LABEL,
    type: 'dbs_uk',
    validation_rules: {
      combo_datasource: [
        {
          key: 'id',
          source: 'background_checks_uk',
        },
        {
          key: 'data',
          source: 'background_checks_uk',
        },
        {
          key: 'validation',
          source: 'background_checks_validation_uk',
        },
      ],
    },
    is_default: true,
  },
];

export const DEFAULT_BOOKING_COLUMNS_US: CreateBookingColumn[] = [
  {
    label: 'Status',
    type: 'string',
    validation_rules: {
      datasource: { key: 'booking_status', source: 'booking_date' },
    },
  },
  {
    label: 'voucher_status',
    type: 'string',
    validation_rules: {
      datasource: { key: 'voucher_status', source: 'booking_date' },
    },
  },
  {
    label: 'Role',
    type: 'text',
    validation_rules: {},
  },
  {
    label: 'Type',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'First Name',
    type: 'string',
    validation_rules: { datasource: { key: 'first_name', source: 'user' } },
  },
  { label: 'role_description', type: 'string', validation_rules: {} },
  {
    label: 'Last Name',
    type: 'string',
    validation_rules: { datasource: { key: 'last_name', source: 'user' } },
  },
  {
    label: 'union_status',
    type: 'string',
    validation_rules: {
      datasource: { key: 'union_status', source: 'user' },
    },
  },
  {
    label: 'Gender',
    type: 'string',
    validation_rules: {
      datasource: { key: 'gender', source: 'actor_info' },
    },
  },
  {
    label: 'Ethnicity',
    type: 'string',
    validation_rules: {
      datasource: {
        key: ['primary_ethnicity', 'secondary_ethnicity', 'tertiary_ethnicity'],
        source: 'actor_info',
      },
    },
  },
  {
    label: 'Age Range',
    type: 'string',
    validation_rules: {
      datasource: {
        key: ['min_portrayable_age', 'max_portrayable_age'],
        source: 'actor_info',
      },
    },
  },
  {
    label: 'birthday',
    type: 'string',
    validation_rules: {
      datasource: { key: 'birthday', source: 'actor_info' },
    },
  },
  {
    label: 'Service',
    type: 'string',
    validation_rules: {
      datasource: { key: 'call_in_service', source: 'actor_info' },
    },
  },
  {
    label: 'Phone',
    type: 'string',
    validation_rules: {
      combo_datasource: [
        { key: 'phone', source: 'user_phone' },
        { key: 'opt_in', source: 'user_notification_preference' },
      ],
    },
  },
  {
    label: 'Email',
    type: 'string',
    validation_rules: { datasource: { key: 'email', source: 'user' } },
  },
  {
    label: 'Rate',
    type: 'rate',
    validation_rules: {},
  },
  {
    label: 'Allowance',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'Call Time',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'Location',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'height',
    type: 'string',
    validation_rules: {
      datasource: { key: 'height', source: 'actor_info' },
    },
  },
  {
    label: 'weight',
    type: 'string',
    validation_rules: {
      datasource: { key: 'weight', source: 'actor_info' },
    },
  },
  {
    label: 'dress',
    type: 'string',
    validation_rules: {
      datasource: { key: 'dress', source: 'actor_info' },
    },
  },
  {
    label: 'pants',
    type: 'string',
    validation_rules: {
      datasource: { key: 'pants', source: 'actor_info' },
    },
  },
  {
    label: 'hip',
    type: 'string',
    validation_rules: { datasource: { key: 'hip', source: 'actor_info' } },
  },
  {
    label: 'waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'waist', source: 'actor_info' },
    },
  },
  {
    label: 'chest',
    type: 'string',
    validation_rules: {
      datasource: { key: 'chest_bust', source: 'actor_info' },
    },
  },
  {
    label: 'neck',
    type: 'string',
    validation_rules: { datasource: { key: 'neck', source: 'actor_info' } },
  },
  {
    label: 'sleeve',
    type: 'string',
    validation_rules: {
      datasource: { key: 'sleeve', source: 'actor_info' },
    },
  },
  {
    label: 'inseam',
    type: 'string',
    validation_rules: {
      datasource: { key: 'inseam', source: 'actor_info' },
    },
  },
  {
    label: 'shirt',
    type: 'string',
    validation_rules: {
      datasource: { key: 'shirt_size', source: 'actor_info' },
    },
  },
  {
    label: 'shoe',
    type: 'string',
    validation_rules: {
      datasource: { key: 'shoe_size', source: 'actor_info' },
    },
  },
  {
    label: 'hat',
    type: 'string',
    validation_rules: { datasource: { key: 'hat', source: 'actor_info' } },
  },
  {
    label: 'bra_size',
    type: 'string',
    validation_rules: {
      datasource: { key: ['bust', 'cup'], source: 'actor_info' },
    },
  },
  {
    label: 'Wardrobe',
    type: 'wardrobe',
    validation_rules: {},
  },
  {
    label: 'hair_makeup',
    type: 'hair_makeup',
    validation_rules: {},
  },
  {
    label: 'Replacement Notes',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'Notes',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'check_in_number',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'pu_time',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'PU Location',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'I-9',
    type: 'i9',
    validation_rules: {
      combo_datasource: [
        {
          key: 'status',
          source: 'i9_docs',
        },
        {
          key: 'expiration_date',
          source: 'i9_docs',
        },
        {
          key: 'latest_action_date',
          source: 'i9_docs',
        },
        {
          key: 'project_name',
          source: 'i9_docs',
        },
        {
          key: 'rejection_notes',
          source: 'i9_docs',
        },
      ],
    },
    is_default: true,
  },
].map((col) => ({ ...col, is_default: true, created_source: 'bookings' }));

const DEFAULT_BOOKING_COLUMNS_UK_MEASUREMENTS = [
  {
    label: 'Dress (UK)',
    type: 'string',
    validation_rules: {
      datasource: { key: 'uk_dress', source: 'actor_info' },
    },
  },
  {
    label: 'Collar',
    type: 'string',
    validation_rules: {
      datasource: { key: 'neck', source: 'actor_info' },
    },
  },
  {
    label: 'Chest/Bust',
    type: 'string',
    validation_rules: {
      datasource: { key: 'chest_bust', source: 'actor_info' },
    },
  },
  {
    label: 'Chest',
    type: 'string',
    validation_rules: {
      datasource: { key: 'chest', source: 'actor_info' },
    },
  },
  {
    label: 'Waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'waist', source: 'actor_info' },
    },
  },
  {
    label: 'Natural Waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'natural_waist', source: 'actor_info' },
    },
  },
  {
    label: 'Trouser Waist',
    type: 'string',
    validation_rules: {
      datasource: { key: 'trouser_waist', source: 'actor_info' },
    },
  },
  {
    label: 'Hips',
    type: 'string',
    validation_rules: {
      datasource: { key: 'hip', source: 'actor_info' },
    },
  },
  {
    label: 'Inside Leg',
    type: 'string',
    validation_rules: {
      datasource: { key: 'inseam', source: 'actor_info' },
    },
  },
  {
    label: 'Shoe (UK)',
    type: 'string',
    validation_rules: {
      datasource: { key: 'uk_shoe', source: 'actor_info' },
    },
  },
  {
    label: 'Sleeve',
    type: 'string',
    validation_rules: {
      datasource: { key: 'sleeve', source: 'actor_info' },
    },
  },
  {
    label: 'Head',
    type: 'string',
    validation_rules: {
      datasource: { key: 'head', source: 'actor_info' },
    },
  },
  {
    label: 'bra_size',
    type: 'string',
    validation_rules: {
      datasource: { key: ['underbust', 'cup'], source: 'actor_info' },
    },
  },
];
export const DEFAULT_BOOKING_COLUMNS_UK: CreateBookingColumn[] = [
  {
    label: 'Status',
    type: 'string',
    validation_rules: {
      datasource: { key: 'booking_status', source: 'booking_date' },
    },
  },
  {
    label: 'voucher_status',
    type: 'string',
    validation_rules: {
      datasource: { key: 'voucher_status', source: 'booking_date' },
    },
  },
  {
    label: 'Role',
    type: 'text',
    validation_rules: {},
  },
  {
    label: 'Type',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'First Name',
    type: 'string',
    validation_rules: { datasource: { key: 'first_name', source: 'user' } },
  },
  { label: 'role_description', type: 'string', validation_rules: {} },
  {
    label: 'Last Name',
    type: 'string',
    validation_rules: { datasource: { key: 'last_name', source: 'user' } },
  },
  {
    label: 'Gender',
    type: 'string',
    validation_rules: {
      datasource: { key: 'gender', source: 'actor_info' },
    },
  },
  {
    label: 'Ethnicity',
    type: 'string',
    validation_rules: {
      datasource: {
        key: ['primary_ethnicity', 'secondary_ethnicity', 'tertiary_ethnicity'],
        source: 'actor_info',
      },
    },
  },
  {
    label: 'Age',
    type: 'string',
    validation_rules: {
      datasource: { key: 'birthday', source: 'actor_info' },
    },
  },
  {
    label: 'Phone',
    type: 'string',
    validation_rules: {
      combo_datasource: [
        { key: 'phone', source: 'user_phone' },
        { key: 'opt_in', source: 'user_notification_preference' },
      ],
    },
  },
  {
    label: 'Email',
    type: 'string',
    validation_rules: { datasource: { key: 'email', source: 'user' } },
  },
  {
    label: 'Call Time',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'Location',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'height',
    type: 'string',
    validation_rules: {
      datasource: { key: 'height', source: 'actor_info' },
    },
  },
  // start of measurements
  ...DEFAULT_BOOKING_COLUMNS_UK_MEASUREMENTS,
  // end of measurements
  {
    label: 'Wardrobe',
    type: 'wardrobe',
    validation_rules: {},
  },
  {
    label: 'hair_makeup',
    type: 'hair_makeup',
    validation_rules: {},
  },
  {
    label: 'Replacement Notes',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'Notes',
    type: 'string',
    validation_rules: {},
  },
  {
    label: RATE_TYPE_LABEL,
    type: 'rate_type',
    validation_rules: {},
  },
  {
    label: RATE_NOTE_LABEL,
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'Type of Day',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'check_in_number',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'pu_time',
    type: 'string',
    validation_rules: {},
  },
  {
    label: 'PU Location',
    type: 'string',
    validation_rules: {},
  },
].map((col) => ({ ...col, is_default: true, created_source: 'bookings' }));

export const UK_BOOKING_COLUMN_LABEL_TO_DB_FIELD =
  DEFAULT_BOOKING_COLUMNS_UK_MEASUREMENTS.reduce(
    (acc, column) => {
      if (
        column?.label &&
        column?.validation_rules?.datasource?.key &&
        typeof column.validation_rules.datasource.key === 'string'
      ) {
        acc[column.label] = column.validation_rules.datasource.key;
      }
      return acc;
    },
    { Age: 'birthday' } as Record<string, string>
  );

export const US_BOOKING_COLUMN_LABEL_TO_DB_FIELD = {
  chest: 'chest_bust',
};
