import { ReactElement, ReactNode, useEffect } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ConfigProvider, message } from 'antd';
import { OnboardingContextProvider } from 'context/OnboardingContext';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { ClientEnvironmentProvider } from '@context/ClientEnvironmentContext/ClientEnvironmentContext';
import configureAmplify from '@lib/amplify.lib';
import { AuthProvider } from '@lib/auth';
import { withErrorHandling } from '@lib/errors';
import { configurePosthog } from '@lib/posthog.lib';
import { store } from '@lib/redux.lib';
import { setGoogleMapsApiLoaded } from '@lib/redux.lib/slices/app/appSlice';
import type { RootState } from '@lib/redux.lib/store';
import antTheme from '@styles/antTheme';
import theme from '@styles/theme';

import ProtectRoute from './auth/protect';

import '@styles/global.css';
import '@styles/overlays.css';
import '@styles/reset.css';
import 'antd/dist/reset.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-crop/dist/ReactCrop.css';

configureAmplify();
configurePosthog();

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  isPublic?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const GoogleMapsApi = () => {
  const { loaded } = useSelector((state: RootState) => state.app.google);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loaded) dispatch(setGoogleMapsApiLoaded(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !loaded ? (
    <Script
      type='text/javascript'
      src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places&callback=Function.prototype&loading=async`}
      onError={(googleMapsError) => {
        console.log({ googleMapsError });
        message.error(
          'Google Maps API failed to load. Please refresh the page.'
        );
      }}
    />
  ) : (
    <></>
  );
};

const setFaviconWithInitials = (initials: string) => {
  // Create a canvas
  const canvas = document.createElement('canvas');
  const size = 64; // Favicon size
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');

  if (!ctx) return; // Ensure context exists

  // Draw a rounded rectangle
  const borderRadius = 16; // Adjust the radius as needed
  ctx.fillStyle = theme.palette.primary[0];
  ctx.beginPath();
  ctx.moveTo(borderRadius, 0);
  ctx.lineTo(size - borderRadius, 0);
  ctx.quadraticCurveTo(size, 0, size, borderRadius);
  ctx.lineTo(size, size - borderRadius);
  ctx.quadraticCurveTo(size, size, size - borderRadius, size);
  ctx.lineTo(borderRadius, size);
  ctx.quadraticCurveTo(0, size, 0, size - borderRadius);
  ctx.lineTo(0, borderRadius);
  ctx.quadraticCurveTo(0, 0, borderRadius, 0);
  ctx.closePath();
  ctx.fill();

  // Draw the initials in white
  ctx.fillStyle = 'white';
  ctx.font = 'bold 48px Inter';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  const verticalOffset = 2; // Fine-tune this value as needed
  ctx.fillText(initials, size / 2, size / 2 + verticalOffset);

  // Convert canvas to a data URL
  const faviconUrl = canvas.toDataURL('image/png');

  // Set the favicon
  let favicon = document.querySelector("link[rel='icon']");
  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    document.head.appendChild(favicon);
  }
  favicon.href = faviconUrl;
};

// const setFaviconWithLogo = (iconUrl: string) => {
//   // Look for an existing favicon tag
//   let favicon = document.querySelector("link[rel='icon']");

//   // If not found, create a new one
//   if (!favicon) {
//     favicon = document.createElement('link');
//     favicon.rel = 'icon';
//     document.head.appendChild(favicon);
//   }

//   // Set the favicon URL
//   favicon.href = iconUrl;
// };

const App = ({ Component, pageProps }: AppPropsWithLayout): ReactElement => {
  const routeProtected = !Component?.isPublic; // "protected" is reserved word and wouldn't track nonexistence
  const getLayout = Component?.getLayout || ((page: ReactElement) => page);
  const View = withErrorHandling((props) => <Component {...props} />);
  const Page: ReactNode = getLayout(<View {...pageProps} />);

  const router = useRouter();
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientName = process.env.NEXT_PUBLIC_CLIENT_NAME;
  const isEveryset = clientName === 'Everyset';

  useEffect(() => {
    if (isEveryset) return;
    const initials = clientName?.[0].toUpperCase();
    setFaviconWithInitials(initials);
    // Saving this for future use
    // if (clientLogoUrl) {
    //   setFaviconWithLogo(clientLogoUrl);
    // } else {
    //   setFaviconWithInitials(initials);
    // }
  }, []);
  return (
    <>
      <Head>
        <title>Casting | {clientName}</title>
      </Head>
      <PostHogProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
              <ClientEnvironmentProvider>
                <OnboardingContextProvider>
                  <AuthProvider>
                    <ConfigProvider theme={antTheme}>
                      <ProtectRoute routeProtected={routeProtected}>
                        <GoogleMapsApi />
                        <main>{Page}</main>
                      </ProtectRoute>
                    </ConfigProvider>
                  </AuthProvider>
                </OnboardingContextProvider>
              </ClientEnvironmentProvider>
            </ReduxProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </PostHogProvider>
    </>
  );
};

export default App;
