export const MagicWand = ({
  width = 20,
  height = 20,
  stroke = '#494949',
  ...otherProps
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        d='M15.525 4.47504L16.125 3.87504M16.1417 11.9917L15.5083 11.35M8.63334 4.47504L8.03334 3.87504M11 11.35L8.64167 9.00004M12.075 2.93337V2.08337M17.0667 7.92504H17.9167M3.50417 17.6725L2.3275 16.495C2.00167 16.1692 2.00167 15.6417 2.3275 15.3159L10.3083 7.33171C10.6342 7.00587 11.1617 7.00587 11.4867 7.33171L12.6633 8.50921C12.9892 8.83504 12.9892 9.36254 12.6633 9.68837L4.68251 17.6725C4.35751 17.9984 3.83 17.9984 3.50417 17.6725Z'
        stroke={stroke}
        stroke-width='1.3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
