import { ReactElement, useState } from 'react';
import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import { Divider, message, Row } from 'antd';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

import { GoogleLogo } from '@assets/icons';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Button } from '@components/Button';
import { EverysetLogoLink } from '@components/EverysetLogoLink';
import { Form } from '@components/Form';
import { Input, PasswordInput } from '@components/Input';
import { displayError } from '@lib/utils/methods';

interface IFieldValues {
  email?: string;
  password?: string;
}

const signIn = async (
  fieldValues: IFieldValues,
  setSignInLoading: (loading: boolean) => void,
  router: NextRouter
): Promise<void> => {
  await (Auth as any)._storage.clear();
  await (Auth as any).cleanCachedItems();
  // disable Log In button while loading to prevent multiple clicks
  setSignInLoading(true);

  const { email, password } = fieldValues;

  const username = email?.toLowerCase()?.trim();
  try {
    // SignIn on Cognito -- will trigger event for listener in @lib/auth/index.tsx
    await Auth.signIn(username, password);
    message.info('Successfully logged in!');
    // if prev redirected to login (e.g. from signup page)
    // then redirect to home page on sign in
    if (router.pathname === '/auth/login') {
      router.push('/');
    }
  } catch (error) {
    if (error?.code === 'UserNotConfirmedException') {
      try {
        await Auth.resendSignUp(username);
        // next js router will url encode params -- need to pass as string literal
        router.push(`/auth/post-signup?email=${username}`);
      } catch (resendError) {
        console.error(resendError); // intentional
      }
      return;
    }
    console.error(error); // intentional
    displayError(error?.message);
  } finally {
    setSignInLoading(false);
  }
};

const EmailFormItem = (props): ReactElement => (
  <Form.Item
    name={'email'}
    rules={[
      {
        required: true,
        message: 'Email is required!',
        validator: (rules, email, callback): Promise<any> => {
          if (email) return Promise.resolve();
          return Promise.reject();
        },
      },
    ]}
    style={{ maxWidth: 352, margin: '0 0 16px 0' }}
  >
    <Input
      name='email'
      label='Email'
      requiredSymbol
      labelStyle={{ fontWeight: 600, fontSize: 12 }}
      placeholder='name@example.com'
      style={{ maxWidth: 352, height: 35, margin: '4px 0 0 0' }}
      data-testid='email-input'
    ></Input>
  </Form.Item>
);

const PasswordFormItem = (props): ReactElement => (
  <Form.Item
    name={'password'}
    rules={[
      {
        required: true,
        message: 'Password is required!',
        validator: (rules, password, callback): Promise<any> => {
          if (password) return Promise.resolve();
          return Promise.reject();
        },
      },
    ]}
    style={{
      maxWidth: 352,
      margin: '0 0 8px 0',
    }}
  >
    <PasswordInput
      name='password'
      label='Password'
      labelStyle={{ fontWeight: 600, fontSize: 12 }}
      placeholder='*****'
      requiredSymbol
      style={{
        maxWidth: 352,
        height: 35,
        margin: '4px 0 0 0',
      }}
    />
  </Form.Item>
);

const ForgotPasswordLink = (props): ReactElement => (
  <div style={{ display: 'flex', maxWidth: 352, margin: '0 0 16px 0' }}>
    <StyledLink href='/auth/forgot-password' data-testid='forgot-pwd-link'>
      Forgot your password?
    </StyledLink>{' '}
  </div>
);

const SignInFormItem = ({
  signInLoading,
}: {
  signInLoading: boolean;
}): ReactElement => (
  <Form.Item
    style={{
      maxWidth: 352,
      margin: 0,
    }}
  >
    <Button
      type='primary'
      htmlType='submit'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 0,
        maxWidth: '100%',
        width: 352,
        maxHeight: 36,
        fontSize: 12,
      }}
      disabled={signInLoading}
      data-testid='login-btn'
    >
      Log in
    </Button>
  </Form.Item>
);

const GoogleButton = (): ReactElement => (
  <StyledGoogleButton
    type='default'
    data-testid='google-btn'
    onClick={() => {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      })
        .then((success) => {
          console.debug('sucess', success);
        })
        .catch((err) => {
          console.debug('ERROR', err);
        });
    }}
    style={{ borderRadius: 4, borderWidth: 2 }}
  >
    <GoogleLogo />
    <span style={{ paddingLeft: 8 }}>Log in with Google</span>
  </StyledGoogleButton>
);

const SignUpLink = (): ReactElement => (
  <div style={{ fontSize: 12 }}>
    <span style={{ marginTop: 2 }}>Don&apos;t have an account?</span>
    {/* passHreft and legacyBehavior required for styled component link*/}
    <Link href='/auth/signup' passHref legacyBehavior>
      <StyledALink data-testid='signup-link'>Sign up here</StyledALink>
    </Link>
  </div>
);

const LoginIndex = (): ReactElement => {
  const [form] = Form.useForm<IFieldValues>();
  const [fieldValues, setFieldValues] = useState<IFieldValues>({});
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const router: NextRouter = useRouter();

  return (
    <Row
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 68,
        marginBottom: 21,
      }}
    >
      <div style={{ marginBottom: 36 }}>
        <EverysetLogoLink width={160} />
      </div>
      <Form
        form={form}
        name='signIn'
        onFinish={() => signIn(fieldValues, setSignInLoading, router)}
        layout={'vertical'}
        onValuesChange={(changedValues: IFieldValues, values: IFieldValues) => {
          // changedValues - only update changed value
          // values - update all fields
          setFieldValues({ ...fieldValues, ...changedValues });
        }}
        style={{
          margin: 0,
          maxWidth: 352,
        }}
      >
        <EmailFormItem />
        <PasswordFormItem />
        <ForgotPasswordLink />
        <SignInFormItem signInLoading={signInLoading} />
      </Form>
      <StyledDivider>or</StyledDivider>
      <GoogleButton />
      <SignUpLink />
    </Row>
  );
};

const StyledDivider = styled(Divider)`
  margin: 16px 0 16px 0;
  justify-content: center;
  max-width: 352px;
  &::before {
    max-width: 158px;
    border-block-start-color: ${(props) =>
      props.theme?.palette?.greyscale?.[4]} !important;
  }
  span.ant-divider-inner-text {
    font-size: 12px;
    padding: 0px 10px;
    color: ${(props) =>
      props?.theme ? props.theme?.palette?.greyscale?.[5] : 'inherit'};
  }
  &::after {
    max-width: 158px;
    border-block-start-color: ${(props) =>
      props.theme?.palette?.greyscale?.[4]} !important;
  }
`;

const StyledGoogleButton = styled(Button)`
  &.ant-btn {
    justify-content: center;
    align-items: center;
    max-width: 352px;
    width: 352px;
    height: 36px;
    padding: 10px 13px;
    margin: 0 0 16px 0;
    span {
      font-size: 12px;
    }
    color: ${(props) => props.theme?.palette?.greyscale?.[10]};
    background-color: ${(props) => props.theme?.palette?.greyscale?.[0]};
    border-color: ${(props) => props.theme?.palette?.greyscale?.[3]};
  }
`;

// external links -- use <a> tag
const StyledLink = styled.a`
  font-size: 12px;
  color: ${(props) => props.theme?.palette?.greyscale?.[6]};
  :hover {
    color: ${(props) => props.theme.palette.primary[0]};
  }
`;

// internal links -- use <a> tag but embed in <Link/> NextJS component
const StyledALink = styled.a`
  font-weight: 600;
  padding-left: 5px;
  color: ${(props) => props.theme?.palette?.primary?.[0]};
  :hover {
    color: ${(props) => props.theme.palette.primary[0]};
  }
`;

LoginIndex.isPublic = true;

export default LoginIndex;
