import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';

export class CastingGalleriesCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Galleries;

  public static createListGalleries(
    castingListId: number,
    gallery: CreateListGallery
  ): Promise<CreateListGalleryReturn> {
    const endpoint = `/projectLists/${castingListId}/galleries`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, gallery)
    );
  }

  public static createBookingGalleries(
    projectId: number,
    gallery: CreateBookingGallery
  ): Promise<CreateBookingGalleryReturn[]> {
    const endpoint = `/projects/${projectId}/galleries`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, gallery)
    );
  }

  public static deleteListGalleryMembers(
    galleryId: number,
    memberIds: number[]
  ): Promise<{ success: boolean }> {
    const endpoint = `/lists/galleries/${galleryId}/members?gallery_member_ids=${JSON.stringify(
      memberIds
    )}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static updateListGallery(
    galleryId: number,
    updateValues: UpdateListGallery
  ): Promise<ListGallery> {
    const endpoint = `/lists/galleries/${galleryId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, updateValues)
    );
  }

  public static updateListGalleryMember(
    galleryMemberId: number,
    updateValues: UpdateListGalleryMember
  ): Promise<ListGalleryMember> {
    const endpoint = `/lists/galleries/members/${galleryMemberId}`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.PUT,
        updateValues,
        {},
        {},
        true
      )
    );
  }

  public static createListGalleryResponseSet(
    galleryId: number,
    responses: CreateListGalleryResponseRequest[]
  ): Promise<ListGalleryResponseSetFull> {
    const endpoint = `/lists/galleries/${galleryId}/responseSet`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        { responses },
        {},
        {},
        true
      )
    );
  }
}
