import { BookedCheck } from '@icons/BookedCheck';
import theme from '@styles/theme';

export const BOOKING_STATUS_OPTIONS = [
  {
    text: 'Status Check',
    abbrev: 'Status Check',
    value: 'available_unconfirmed',
  },
  {
    text: 'Avail Confirmed',
    abbrev: 'Avail Confirmed',
    value: 'first_available',
    fullText: 'Avail Confirmed',
  },
  { text: 'Denied', abbrev: 'Denied', value: 'denied' },
  { text: 'Booked', abbrev: 'Booked', value: 'booked' },
  {
    text: 'Booked \u2713',
    abbrev: 'Booked \u2713',
    value: 'booked_confirmed',
  },
  { text: 'Released', abbrev: 'Released', value: 'released' },
  { text: 'Canceled', abbrev: 'Canceled', value: 'canceled' },
  {
    text: 'Canceled w/ Pay',
    abbrev: 'Canceled w/ Pay',
    value: 'canceled_w_pay',
  },
  { text: 'No Show', abbrev: 'No Show', value: 'no_show' },
];

export const STATUS_TO_DISPLAY_VALUE = BOOKING_STATUS_OPTIONS.reduce(
  (prev, curr) => {
    prev[curr.value] = curr;
    return prev;
  },
  {}
);

export const BOOKING_STATUS_MENU_OPTIONS = [
  {
    text: 'Status Check',
    abbrev: 'Status Check',
    value: 'available_unconfirmed',
  },
  {
    text: 'Avail Confirmed',
    abbrev: 'Avail Confirmed',
    value: 'first_available',
  },
  { text: 'Denied', abbrev: 'Denied', value: 'denied' },
  { text: 'Booked', abbrev: 'Booked', value: 'booked' },
  { text: 'Booked \u2713', abbrev: 'Booked \u2713', value: 'booked_confirmed' },
  { text: 'Released', abbrev: 'Released', value: 'released' },
  { text: 'Canceled', abbrev: 'Canceled', value: 'canceled' },
  {
    text: 'Canceled w/ Pay',
    abbrev: 'Canceled w/ Pay',
    value: 'canceled_w_pay',
  },
  { text: 'No Show', abbrev: 'No Show', value: 'no_show' },
];

export const BOOKING_STATUS_TO_NOTIFICATION_TYPE = {
  available_unconfirmed: 'available_unconfirmed', // temporary
  first_available: 'changed_status_bookings',
  booking_details: 'booking_details',
  booked: 'booking_details',
  booked_confirmed: 'booking_details',
  released_multi_date: 'released_multi_date',
  released_single_date: 'released_single_date',
  canceled: 'changed_status_bookings',
  canceled_w_pay: 'canceled_w_pay',
  no_show: 'changed_status_bookings',
  denied: 'changed_status_bookings',
  misc_message_bookings: 'misc_message_bookings', // not a status - but necessary
};

export const BOOKING_STATUS_MAPPINGS = BOOKING_STATUS_OPTIONS.reduce(
  (acc, curr) => {
    acc[curr.value] = curr.text;
    return acc;
  },
  {
    deleted: 'Deleted', // this one is not in the options
  }
);

export const NON_ACTIVE_BOOKING_STATUSES = [
  'denied',
  'released',
  'canceled',
  'no_show',
];

// BOOKING_STATUS_SORT_ORDER_MAP also exists on casting-galleries service
export const BOOKING_STATUS_SORT_ORDER_MAP = {
  available_unconfirmed: 1, // Status Check
  first_available: 2, // Avail Conf.
  denied: 3,
  booked: 4,
  booked_confirmed: 5, // Booked √
  no_show: 6,
  canceled: 7,
  canceled_w_pay: 8,
  released: 9,
};

export const BOOKING_STATUS_FORMATTING = {
  available_unconfirmed: { backgroundColor: theme.palette.color[1] },
  first_available: { backgroundColor: theme.palette.color[2] },
  booked: { backgroundColor: theme.palette.primary[0] },
  booked_confirmed: {
    backgroundColor: theme.palette.primary[0],
    icon: BookedCheck,
  },
  released: { backgroundColor: theme.palette.greyscale[6] },
  canceled: { backgroundColor: theme.palette.color[0] },
  canceled_w_pay: { backgroundColor: theme.palette.color[0] },
  no_show: { backgroundColor: theme.palette.color[0] },
  denied: { backgroundColor: theme.palette.color[0] },
  deleted: { backgroundColor: theme.palette.greyscale[6] },
} as const;

export const POSITIVE_BOOKING_STATUSES = [
  'first_available', // Avail Conf.
  'available_unconfirmed', // Status Check
  'booked_confirmed', // Booked √
  'booked',
  'canceled_w_pay',
];
