import type { SVGProps } from 'react';

const Star = ({
  width = 20,
  height = 20,
  className = '',
  stroke = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className + ' star-icon'}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 14.3625L5.14917 16.6667L6.15667 11.5667L2.5 7.82667L7.64917 7.18417L10 2.5L12.3508 7.18417L17.5 7.82667L13.8433 11.5667L14.8508 16.6667L10 14.3625Z'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export { Star };
