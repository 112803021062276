import type { SVGProps } from 'react';

import theme from '@styles/theme';

const EditPen = ({
  width = 20,
  height = 21,
  stroke = theme.palette.color[12],
  ...otherProps
}: SVGProps<SVGSVGElement>): JSX.Element => {
  const dataTestId = otherProps?.['data-testid'] || 'edit-pen-icon';
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
      data-testid={dataTestId}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.41067 13.7032L13.0157 5.09824C13.3407 4.77324 13.8682 4.77324 14.1932 5.09824L15.5898 6.49491C15.9148 6.81991 15.9148 7.34741 15.5898 7.67241L6.984 16.2766C6.82817 16.4332 6.6165 16.5207 6.39567 16.5207H4.1665V14.2916C4.1665 14.0707 4.254 13.8591 4.41067 13.7032Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.4585 6.6543L14.0335 9.2293'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export { EditPen };
