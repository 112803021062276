import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';

export class PhotosCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Photos;

  public static upsertUserProfilePicture(
    userId: number,
    newUrl: string
  ): Promise<UserPhoto> {
    const url = `/users/${userId}/profilePicture`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, url, HttpMethod.PUT, { photo_url: newUrl })
    );
  }

  public static async uploadImage(
    uploadUrl: UploadUrl,
    photoFile: File | Blob
  ): Promise<string | void> {
    const formData = this._photoFormData(uploadUrl, photoFile);
    const options = this._photoRequestOptions(formData);
    try {
      const response = await fetch(uploadUrl.url, options);
      return response.text();
    } catch (error) {
      console.log({ error });
    }
  }

  public static attachPhotoToProject(
    projectId: number,
    photoUrl: string
  ): Promise<Project> {
    const body = {
      image_thumbnail: photoUrl,
      image_header: photoUrl,
    };
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/project/${projectId}`,
        HttpMethod.PUT,
        body
      )
    );
  }

  public static createUserPhoto(userPhoto: UserPhotoDTO): Promise<UserPhoto> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/user/${userPhoto.user_id}`,
        HttpMethod.POST,
        userPhoto
      )
    );
  }

  public static updateUserPhoto(
    photoId: number,
    newValues: UpdateUserPhoto
  ): Promise<UserPhoto> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/user/${photoId}`,
        HttpMethod.PUT,
        newValues
      )
    );
  }

  private static _photoFormData(
    uploadUrl: UploadUrl,
    photoFile: File | Blob
  ): FormData {
    const result = new FormData();
    for (const [key, value] of Object.entries(uploadUrl.fields)) {
      result.append(key, value);
    }
    result.append('file', photoFile, uploadUrl.fields.key);
    result.append('type', photoFile.type);
    return result;
  }

  private static _photoRequestOptions(formData: FormData): RequestInit {
    const result: RequestInit = {
      method: HttpMethod.POST,
      body: formData,
      redirect: 'follow',
    };
    return result;
  }
}
