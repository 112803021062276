import type { SVGProps } from 'react';

export const EyeIcon = ({
  width = 20,
  height = 20,
  stroke = '#323232',
  ...otherProps
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.77569 10.3891C1.63031 10.1466 1.63031 9.85246 1.77569 9.60996C3.52759 6.69413 6.76379 4.16663 9.99999 4.16663C13.2362 4.16663 16.4724 6.69413 18.2243 9.61079C18.3697 9.85329 18.3697 10.1475 18.2243 10.39C16.4724 13.3058 13.2362 15.8333 9.99999 15.8333C6.76379 15.8333 3.52759 13.3058 1.77569 10.3891Z'
      stroke={stroke}
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9642 7.93756C13.049 9.07659 13.049 10.9233 11.9642 12.0624C10.8794 13.2014 9.12059 13.2014 8.0358 12.0624C6.95101 10.9233 6.95101 9.07659 8.0358 7.93756C9.12059 6.79854 10.8794 6.79854 11.9642 7.93756Z'
      stroke={stroke}
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
