// Import the RTK Query methods from the React-specific entry point
import {
  CastingBookingsCommandApi,
  CastingBookingsQueryApi,
} from '@lib/api/endpoints';
import { logIfDevelopment } from '@lib/utils/logging';
import {
  checkResourceId,
  mapErrorFromMessage,
  mapErrorFromResponse,
} from '@lib/utils/methods';
import { current } from '@reduxjs/toolkit';
import {
  BaseQueryApi,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
type QueryFn = (
  arg?,
  api?: BaseQueryApi,
  extraOptions?: BaseQueryExtraOptions<BaseQueryFn>,
  baseQuery?: BaseQueryFn
) => Promise<any>;

const getBookingsHandler: QueryFn = (projectId: number) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;

  return CastingBookingsQueryApi.getBookings(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getBookingDateIdsHandler: QueryFn = ({
  projectId,
  bookingDates,
}: {
  projectId: number;
  bookingDates: string[];
}) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;

  return CastingBookingsQueryApi.getBookingDateIds(projectId, bookingDates)
    .then((response) => {
      /* returns {
            [booking_date_id]: {
              booking_id: [booking_id],
              unit_id: [unit_id]
          }
      } */
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getNextBookingDateHandler: QueryFn = (unitId: number) => {
  const idCheck = checkResourceId(unitId);
  if (idCheck) return idCheck;

  return CastingBookingsQueryApi.getNextBookingDate(unitId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getAllBookingColumnsHandler: QueryFn = (projectId: number) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;

  return CastingBookingsQueryApi.getAllBookingColumns(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getBookingColumnsHandler: QueryFn = ({
  projectId,
  unitId,
  unitDate,
}: {
  projectId: number;
  unitId: number;
  unitDate: string;
}) => {
  const idCheck = checkResourceId(projectId);
  const unitIdCheck = checkResourceId(unitId);
  if (idCheck || unitIdCheck) return idCheck;

  return CastingBookingsQueryApi.getBookingColumns(projectId, unitId, unitDate)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getBookingValuesHandler: QueryFn = ({
  projectId,
  bookingDates,
}: {
  projectId: number;
  bookingDates: string[];
}) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingBookingsQueryApi.getBookingValues(projectId, bookingDates)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getBookingTableRoleGroupSettingsHandler: QueryFn = ({
  bookingDate,
  unitId,
}: {
  bookingDate: string;
  unitId: number;
}) => {
  const idCheck = checkResourceId(unitId);
  if (idCheck) return idCheck;

  return CastingBookingsQueryApi.getBookingTableRoleGroupSettings(
    bookingDate,
    unitId
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getBookingTableSortSettingsHandler: QueryFn = ({
  bookingDate,
  unitId,
}: {
  bookingDate: string;
  unitId: number;
}) => {
  const idCheck = checkResourceId(unitId);
  if (idCheck) return idCheck;

  return CastingBookingsQueryApi.getBookingTableSortSettings(
    bookingDate,
    unitId
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getConflictingBookingDatesHandler: QueryFn = ({
  projectId,
  date,
}: {
  projectId: number;
  date: string;
}) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  if (!date)
    return new Promise((resolve, reject) => reject())?.catch(() => {
      return mapErrorFromMessage('No date provided');
    });
  return CastingBookingsQueryApi.getConflictingBookingDates(projectId, date)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getBookingDateNotesHandler: QueryFn = ({
  projectId,
  bookingDate,
  unitId,
}: {
  projectId: number;
  bookingDate: string;
  unitId: number;
}) => {
  const idChecks = [checkResourceId(projectId), checkResourceId(unitId)];
  if (!idChecks?.every((check) => !check)) return Promise.all(idChecks);
  if (!bookingDate)
    return new Promise((resolve, reject) => reject())?.catch(() => {
      return mapErrorFromMessage('No date provided');
    });
  return CastingBookingsQueryApi.getBookingDateNotes(
    projectId,
    bookingDate,
    unitId
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateBookingDatesHandler: QueryFn = (
  bookingDateDto: UpdateBookingDateDTO
) => {
  return CastingBookingsCommandApi.updateBookingDates(bookingDateDto)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateBookingDatesByBookingIdUnitIdHandler: QueryFn = ({
  projectId,
  bookingDateDtos,
}: {
  projectId: number;
  bookingDateDtos: UpdateBookingDateByBookingIdUnitId[];
}) => {
  return CastingBookingsCommandApi.updateBookingDatesByBookingIdUnitId(
    projectId,
    bookingDateDtos
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const deleteBookingDatesByIdHandler: QueryFn = ({
  projectId,
  bookingDateIds,
}: {
  projectId: number;
  bookingDateIds: number[];
}) => {
  return CastingBookingsCommandApi.deleteBookingDates(projectId, bookingDateIds)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateBookingValuesHandler: QueryFn = (arg: {
  projectId: number;
  bookingValueDtos: UpdateBookingValueDTO[];
}) => {
  if (!arg?.projectId || !Object.values(arg?.bookingValueDtos).length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      logIfDevelopment(err);
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }

  const finalDtos = arg.bookingValueDtos?.map(({ bookingId: _, ...rest }) => ({
    ...rest,
  }));

  return CastingBookingsCommandApi.updateBookingValues(arg.projectId, finalDtos)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const upsertBookingTableRoleGroupSettingsHandler: QueryFn = (
  dtos: UpsertBookingTableRoleGroupSettings[]
) => {
  if (!dtos.length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      logIfDevelopment(err);
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }
  return CastingBookingsCommandApi.upsertBookingTableRoleGroupSettings(dtos)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const upsertBookingTableSortSettingsHandler: QueryFn = (
  dto: BookingTableSortSettings
) => {
  if (!Object.keys(dto).length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      logIfDevelopment(err);
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }
  return CastingBookingsCommandApi.upsertBookingTableSortSettings(dto)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const deleteBookingValuesHandler: QueryFn = (arg: {
  projectId: number;
  bookingValueObjIds: { booking_date_id: number; column_id: number }[];
}) => {
  if (!arg?.projectId || !arg.bookingValueObjIds.length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      logIfDevelopment(err);
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }
  return CastingBookingsCommandApi.deleteBookingValues(
    arg.projectId,
    arg.bookingValueObjIds
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const deleteBookingTableSortSettingsHandler: QueryFn = ({
  bookingDate,
  unitId,
}: {
  bookingDate: string;
  unitId: number;
}) => {
  if (!bookingDate || !unitId) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      logIfDevelopment(err);
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }
  return CastingBookingsCommandApi.deleteBookingTableSortSettings(
    bookingDate,
    unitId
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const createBookingColumnsHandler: QueryFn = async (arg: {
  projectId: number;
  columns: CreateBookingColumn[];
  date?: string;
  unitId?: number;
}) => {
  try {
    const data = await CastingBookingsCommandApi.createBookingColumns(
      arg.projectId,
      arg.columns,
      arg?.date,
      arg?.unitId
    );
    return { data };
  } catch (error) {
    return { error };
  }
};

const updateBookingColumnHandler: QueryFn = async (arg: {
  bookingColumnId: number;
  newValues: UpdateBookingColumn;
}) => {
  try {
    const data = await CastingBookingsCommandApi.updateBookingColumn(
      arg.bookingColumnId,
      arg.newValues
    );
    return { data };
  } catch (error) {
    return { error };
  }
};

const deleteBookingColumnHandler: QueryFn = async (arg: {
  columnIds: number[];
}) => {
  try {
    const data = await CastingBookingsCommandApi.deleteBookingColumns(
      arg.columnIds
    );
    return { data };
  } catch (error) {
    return { error };
  }
};

const updateBookingDateNotesHandler: QueryFn = ({
  projectId,
  dto,
}: {
  projectId: number;
  dto: UpdateBookingDateNotes;
}) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  if (!Object.keys(dto).length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      logIfDevelopment(err);
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }
  return CastingBookingsCommandApi.updateBookingDateNotes(projectId, dto)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// Define our single API slice object
const bookingsApiSlice = createApi({
  reducerPath: 'bookings-api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH }),
  tagTypes: [
    'bookings',
    'bookingDateIds',
    'nextBookingDate',
    'allBookingColumns',
    'bookingColumns',
    'bookingValues',
    'bookingTableSortSettings',
    'bookingTableRoleGroupSettings',
    'conflictingBookingDates',
    'bookingDateNotes',
  ],
  endpoints: (builder) => ({
    // GET
    getBookings: builder.query<BookingWithUserDetails[], number>({
      queryFn: getBookingsHandler,
      providesTags: ['bookings'],
    }),
    getBookingDateIds: builder.query<
      BookingDateIds,
      { projectId: number; bookingDates: string[] }
    >({
      queryFn: getBookingDateIdsHandler,
      providesTags: ['bookingDateIds'],
    }),
    getNextBookingDate: builder.query<string, number>({
      queryFn: getNextBookingDateHandler,
      providesTags: ['nextBookingDate'],
    }),
    getAllBookingColumns: builder.query<BookingColumn[], number>({
      queryFn: getAllBookingColumnsHandler,
      providesTags: ['allBookingColumns'],
    }),
    getBookingColumns: builder.query<
      BookingColumn[],
      { projectId: number; unitId: number; unitDate: string }
    >({
      queryFn: getBookingColumnsHandler,
      providesTags: ['bookingColumns'],
    }),
    getBookingValues: builder.query<
      BookingValue,
      { projectId: number; bookingDates: string[] }
    >({
      queryFn: getBookingValuesHandler,
      providesTags: ['bookingValues'],
    }),
    getBookingTableSortSettings: builder.query<
      BookingTableSortSettings,
      { bookingDate: string; unitId: number }
    >({
      queryFn: getBookingTableSortSettingsHandler,
      providesTags: ['bookingTableSortSettings'],
    }),
    getBookingTableRoleGroupSettings: builder.query<
      BookingTableRoleGroupSettings,
      { bookingDate: string; unitId: number }
    >({
      queryFn: getBookingTableRoleGroupSettingsHandler,
      providesTags: ['bookingTableRoleGroupSettings'],
    }),
    getConflictingBookingDates: builder.query<
      ConflictingBookingDatesResponse,
      { projectId: number; date: string }
    >({
      queryFn: getConflictingBookingDatesHandler,
      providesTags: ['conflictingBookingDates'],
    }),
    getBookingDateNotes: builder.query<
      BookingDateNotes,
      { projectId: number; bookingDate: string; unitId: number }
    >({
      queryFn: getBookingDateNotesHandler,
      providesTags: ['bookingDateNotes'],
    }),

    // PUT
    updateBookingDates: builder.mutation<number, UpdateBookingDateDTO>({
      queryFn: updateBookingDatesHandler,
      invalidatesTags: ['bookingDateIds', 'nextBookingDate', 'bookingValues'],
    }),
    updateBookingDatesByBookingIdUnitId: builder.mutation<
      number,
      {
        projectId: number;
        bookingDateDtos: UpdateBookingDateByBookingIdUnitId[];
      }
    >({
      queryFn: updateBookingDatesByBookingIdUnitIdHandler,
      invalidatesTags: ['bookingDateIds', 'nextBookingDate', 'bookingValues'],
    }),
    deleteBookingDatesById: builder.mutation<
      number,
      { projectId: number; bookingDateIds: number[] }
    >({
      queryFn: deleteBookingDatesByIdHandler,
      invalidatesTags: ['bookingDateIds', 'nextBookingDate', 'bookingValues'],
    }),
    updateBookingValues: builder.mutation<
      BookingColumnValue[],
      {
        projectId: number;
        bookingValueDtos: UpdateBookingValueDTO[];
        selectedBookingDate?: string;
      }
    >({
      queryFn: updateBookingValuesHandler,
      invalidatesTags: ['bookingValues'],
      onQueryStarted: async (
        { projectId, bookingValueDtos, selectedBookingDate },
        { dispatch, queryFulfilled }
      ) => {
        if (!selectedBookingDate) return;
        const patchResult = dispatch(
          bookingsApiSlice.util.updateQueryData(
            'getBookingValues',
            { projectId, bookingDates: [selectedBookingDate] },
            (draft) => {
              let columnValuesToReplace = { ...current(draft.column_values) };
              bookingValueDtos.forEach(
                ({ column_id, booking_date_id, bookingId, values }) => {
                  const bookingDateValues =
                    columnValuesToReplace?.[column_id]?.booking_date_values;
                  if (!bookingDateValues || !bookingId) return;
                  columnValuesToReplace = {
                    ...columnValuesToReplace,
                    [column_id]: {
                      ...columnValuesToReplace[column_id],
                      booking_date_values: {
                        ...columnValuesToReplace[column_id].booking_date_values,
                        [bookingId]: {
                          ...columnValuesToReplace[column_id]
                            .booking_date_values[bookingId], // is fine that this can be undefined
                          [booking_date_id]: values,
                        },
                      },
                    },
                  };
                }
              );
              draft.column_values = columnValuesToReplace;
            }
          )
        );
        await queryFulfilled.catch((error) => {
          logIfDevelopment('Error updating booking values', error);
          patchResult.undo();
        });
      },
    }),
    upsertBookingTableRoleGroupSettings: builder.mutation<
      BookingTableRoleGroupSettings[],
      UpsertBookingTableRoleGroupSettings[]
    >({
      queryFn: upsertBookingTableRoleGroupSettingsHandler,
      invalidatesTags: ['bookingTableRoleGroupSettings'],
      onQueryStarted: async (upsertDtos, { dispatch, queryFulfilled }) => {
        if (!upsertDtos?.length) return;
        const patchResults = upsertDtos.map((dto) => {
          return dispatch(
            bookingsApiSlice.util.updateQueryData(
              'getBookingTableRoleGroupSettings',
              { bookingDate: dto.booking_date, unitId: dto.unit_id },
              (draft) => {
                draft.role_group_order = dto.role_group_order;
                draft.role_user_count = dto.role_user_count;
              }
            )
          );
        });
        await queryFulfilled.catch((error) => {
          logIfDevelopment(
            'Error updating booking table role group settings',
            error
          );
          patchResults.forEach((patchResult) => patchResult.undo());
        });
      },
    }),
    upsertBookingTableSortSettings: builder.mutation<
      null,
      BookingTableSortSettings
    >({
      queryFn: upsertBookingTableSortSettingsHandler,
      invalidatesTags: ['bookingTableSortSettings'],
    }),
    updateBookingColumn: builder.mutation<
      BookingColumn,
      { bookingColumnId: number; newValues: UpdateBookingColumn }
    >({
      queryFn: updateBookingColumnHandler,
      invalidatesTags: ['allBookingColumns', 'bookingColumns'],
    }),
    updateBookingDateNotes: builder.mutation<
      BookingDateNotes,
      { projectId: number; dto: UpdateBookingDateNotes }
    >({
      queryFn: updateBookingDateNotesHandler,
      invalidatesTags: ['bookingDateNotes'],
    }),

    // DELETE
    deleteBookingValues: builder.mutation<
      DeletedBookingValues,
      {
        projectId: number;
        bookingValueObjIds: { booking_date_id: number; column_id: number }[];
      }
    >({
      queryFn: deleteBookingValuesHandler,
      invalidatesTags: ['bookingValues'],
    }),
    deleteBookingTableSortSettings: builder.mutation<
      DeletedBookingValues,
      {
        bookingDate: string;
        unitId: number;
      }
    >({
      queryFn: deleteBookingTableSortSettingsHandler,
      invalidatesTags: ['bookingTableSortSettings'],
    }),
    deleteBookingColumns: builder.mutation<number[], { columnIds: number[] }>({
      queryFn: deleteBookingColumnHandler,
      invalidatesTags: ['allBookingColumns', 'bookingColumns'],
    }),

    // POST
    createBookingColumns: builder.mutation<
      BookingColumn[],
      {
        projectId: number;
        columns: CreateBookingColumn[];
        date?: string;
        unitId?: number;
      }
    >({
      queryFn: createBookingColumnsHandler,
      invalidatesTags: ['allBookingColumns', 'bookingColumns'],
    }),
  }),
});

export const {
  useGetBookingsQuery,
  useGetBookingDateIdsQuery,
  useGetNextBookingDateQuery,
  useGetAllBookingColumnsQuery,
  useGetBookingColumnsQuery,
  useGetBookingValuesQuery,
  useGetBookingTableSortSettingsQuery,
  useGetBookingTableRoleGroupSettingsQuery,
  useGetConflictingBookingDatesQuery,
  useGetBookingDateNotesQuery,
  useUpdateBookingDateNotesMutation,
  useUpdateBookingDatesMutation,
  useUpdateBookingDatesByBookingIdUnitIdMutation,
  useUpdateBookingValuesMutation, // upsert
  useUpsertBookingTableRoleGroupSettingsMutation,
  useUpsertBookingTableSortSettingsMutation,
  useDeleteBookingValuesMutation,
  useDeleteBookingTableSortSettingsMutation,
  useCreateBookingColumnsMutation,
  useUpdateBookingColumnMutation,
  useDeleteBookingColumnsMutation,
  useDeleteBookingDatesByIdMutation,
  reducer,
} = bookingsApiSlice; // hook + reducer export

export { bookingsApiSlice }; // slice export
